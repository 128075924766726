/* THIS FILE IS GENERATED ON RUNTIME. DO NOT EDIT */

import { ComponentClassNameFactory } from 'lib/contexts/ComponentClassNameFactory';
import getPropStylesFactory from 'lib/utils/getPropStylesFactory';
import getComponentStylesFactory from 'lib/utils/getComponentStylesFactory';
import getComponentThemeFactory from 'lib/utils/getComponentThemeFactory';
import make_styler from '../index';

export const styles = {
  "input_colors_default": "bg-white active:bg-gray-50 shadow-gray-400 before:bg-gray-100 after:border-logga-600",
  "input_colors_alternative": "bg-white outline-gray-100 active:bg-gray-50 checked:bg-logga-600 checked:active:bg-logga-700 before:bg-logga-600 after:bg-logga-600 checked:before:bg-white checked:after:bg-white",
  "input_size_default": "h-5 w-5 p-1.25 shadow-inset-1 before:w-[130%] before:h-[130%] before:scale-0 hover:before:scale-100 active:before:scale-0 focus:before:scale-100 after:w-2.5 after:h-1.25 after:border-l-2 after:border-b-2 after:scale-0 checked:after:scale-100",
  "input_size_alternative": "w-9 h-9 outline outline-0 shadow-md hover:outline-4 hover:shadow-inset-0 focus:outline-4 active:outline-0 active:shadow-md checked:shadow-inset-0",
  "input_variant_default": "appearance-none transition-all relative before:block before:absolute before:top-1/2 before:left-1/2 before:-translate-x-1/2 before:-translate-y-1/2 before:transition-all before:-z-2 after:block after:absolute after:top-1/2 after:left-1/2 after:-translate-x-1/2 after:-translate-y-2/3 checked:after:-rotate-45 after:origin-center after:transition-all",
  "input_variant_alternative": "appearance-none transition-all relative rounded-full before:block before:w-2/3 before:h-0.5 before:absolute before:transition-all before:origin-left before:bg-black before:rounded-full before:top-1/2 before:left-1/2 before:-translate-x-1/2 before:-translate-y-1/2 checked:before:w-1/3 checked:before:top-2/5 checked:before:left-2/5 checked:before:rotate-45 checked:before:translate-y-0 after:block after:w-0.5 after:h-2/3 after:absolute after:transition-all after:origin-bottom after:bg-black after:rounded-full after:top-1/2 after:left-1/2 after:-translate-x-1/2 after:-translate-y-1/2 checked:after:h-1/2 checked:after:top-2/5 checked:after:left-2/5 checked:after:rotate-45 checked:after:translate-x-0",
  "label_colors_default": "text-black",
  "label_colors_alternative": "",
  "label_size_default": "text-body-md",
  "label_size_alternative": "",
  "label_variant_alternative": "hidden",
  "caption_colors_default": "",
  "caption_size_default": "w-full",
  "caption_variant_default": "order-1",
  "colors_default": "",
  "colors_alternative": "",
  "size_default": "gap-x-2",
  "size_alternative": "",
  "variant_default": "relative z-1 flex items-center",
  "variant_alternative": "relative z-1"
};

const c = make_styler(styles);

export const propClassName = getPropStylesFactory(c, styles, "Checkbox");

export const componentClassName = getComponentStylesFactory(propClassName);

export const componentTheme = getComponentThemeFactory(styles);

export const {
  ClassNameContext,
  ClassNameProvider,
  useClassName,
  useTheme,
} = ComponentClassNameFactory(componentClassName, styles);
  
export default c;